import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import { FaCheck } from 'react-icons/fa';
import { HiInformationCircle } from 'react-icons/hi2';
import { motion } from 'motion/react';
import { useIndicationCode } from '@/components/Partner/hooks/useIndicationCode';
import { handleClipboardClick } from '@/utils/handleClipboardClick';
import { shareFrowWhatsApp } from '@/utils/shareFrowWhatsApp';

export const IndicationCodeForUsers = () => {
  const [isCopied, setIsCopied] = useState(false);
  const { indicationCode } = useIndicationCode();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (indicationCode) {
      setLoading(false);
    }
  }, [indicationCode]);

  const handleGoIndicationPage = () => {
    window.location.href = '/indicar';
  };

  return (
    <div className="mt-10 mb-10">
      <motion.div
        className="bg-[rgba(255,255,255,0.6)] rounded-t-xl p-5 flex gap-3 cursor-pointer hover:bg-opacity-80 transition shadow-md hover:shadow-lg"
        onClick={handleGoIndicationPage}
        whileTap={{ scale: 0.95 }}
        whileHover={{ scale: 1.01 }}>
        <HiInformationCircle color="#D8F42E" size={70} />
        <div className="flex flex-col">
          <motion.div
            className="font-semibold sm:text-[18px] flex"
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}>
            Código de indicação para usuários
          </motion.div>
          <motion.div
            className="font-normal sm:text-[14px] flex mt-2 mb-2"
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.7 }}>
            Agora você pode indicar usuários e receber benefícios exclusivos.
          </motion.div>
        </div>
      </motion.div>
      <motion.div
        className="bg-main-green-500 rounded-b-xl p-5"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}>
        <motion.div
          className="font-semibold text-1xl sm:text-3xl flex items-center "
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}>
          {loading ? (
            <div className="w-6 h-6 border-4 border-white border-t-transparent rounded-full animate-spin"></div>
          ) : (
            indicationCode
          )}
        </motion.div>
        <div className="flex gap-4 mt-5">
          <motion.button
            className="bg-white rounded-[10px] p-[12px] h-12 w-[100%] flex items-center justify-center hover:bg-gray-200 transition shadow-md hover:shadow-lg"
            whileTap={{ scale: 0.9 }}
            whileHover={{ scale: 1.05 }}
            onClick={() => handleClipboardClick(indicationCode, setIsCopied)}
            disabled={loading}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="flex gap-2">
              <Image
                src="/clipboard.svg"
                alt="clipboard"
                width={19}
                height={19}
              />
              {isCopied ? (
                <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }}>
                  <FaCheck className="h-8 w-8 text-green-500" />
                </motion.div>
              ) : (
                <p className="hidden sm:block text-[12px]">Copiar código</p>
              )}
            </motion.div>
          </motion.button>
          <motion.button
            className="bg-white rounded-[10px] p-[12px] w-[100%] h-12 flex items-center justify-center hover:bg-gray-200 transition shadow-md hover:shadow-lg"
            onClick={() => shareFrowWhatsApp(indicationCode)}
            whileTap={{ scale: 0.9 }}
            whileHover={{ scale: 1.05 }}
            disabled={loading}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="flex gap-2">
              <Image src="/share.svg" alt="share" width={20} height={20} />
              <p className="hidden sm:block text-[12px]">Compartilhar</p>
            </motion.div>
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
};

export default IndicationCodeForUsers;
